import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Tabs, Button } from 'antd';

import payrollApi from 'api/payrollApi';
import { ThirteenthMonthSalaryData, ThirteenthMonthSalaryFilters } from 'types';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { PAYROLL_YEAR_SORT_FIELDS, SORT_TYPES } from 'constants/payroll';
import ThirteenthMonthSalaryList from './ThirteenthMonthSalaryList';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { message } from 'antd';
import { debounce } from 'lodash';

const SearchWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-input {
    .ant-input-affix-wrapper {
      max-width: 300px;
    }
  }

  .left-section {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .tabs-wrapper {
    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-tab {
      min-width: 120px;
      padding: 8px 24px;
      justify-content: center;
      transition: none !important;

      .ant-tabs-tab-btn {
        text-align: center;
        transition: none !important;
      }
    }

    .ant-tabs-ink-bar {
      transition: none !important;
    }
  }
`;

interface ThirteenthMonthSalaryProps {
  year: string;
}

const ThirteenthMonthSalary: React.FC<ThirteenthMonthSalaryProps> = ({ year }) => {
  const { t } = useTranslation(['payroll', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ThirteenthMonthSalaryData[]>([]);
  const [totalResult, setTotalResult] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState('gross');
  const [isCreating, setIsCreating] = useState(false);

  const [filters, setFilters] = useState<ThirteenthMonthSalaryFilters>({
    search: '',
    year: year,
    include13thMonth: true,
  });

  // Cập nhật filters khi year thay đổi
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      year: year,
      include13thMonth: true,
    }));
  }, [year]);

  const filtersMemo = useMemo(
    () => ({
      ...filters,
      include13thMonth: true,
      offset: page,
      limit,
      search: searchText.trim(),
    }),
    [filters, page, limit, searchText],
  );

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await payrollApi.getThirteenthMonthSalary(filtersMemo);
      const newData = response?.data?.data || [];
      setData(newData);
      setTotalResult(response.data?.total || 0);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error(t('common:message.error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filtersMemo]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    // Chỉ xử lý pagination
    if (pagination.current && pagination.current !== page) {
      setPage(pagination.current);
    }
    if (pagination.pageSize && pagination.pageSize !== limit) {
      setLimit(pagination.pageSize);
      setPage(1);
    }
  };

  // Debounce search để tránh gọi API quá nhiều
  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchText(value);
      setPage(1); // Reset về trang 1 khi search
    }, 500),
    [],
  );

  const handleCreate13thSalary = async () => {
    try {
      setIsCreating(true);
      const response = await payrollApi.createPayroll13th(year);
      if (response?.data?.success) {
        message.success(t('payroll:refresh_13th_month_success'));
        fetchData(); // Refresh data after creating
      }
    } catch (error: any) {
      console.error('Error creating 13th month salary:', error);
      if (error?.statusCode === 404) {
        message.error(t('payroll:refresh_13th_month_failed'));
      } else {
        message.error(t('common:message.error'));
      }
    } finally {
      setIsCreating(false);
    }
  };

  const refetchData = useCallback(() => {
    fetchData();
  }, [filtersMemo]);

  return (
    <ContainerBgWhite>
      <SearchWrapper>
        <div className="left-section">
          <div className="search-input">
            <Input
              placeholder={t('common:search by Name & ID')}
              prefix={<SearchOutlined />}
              onChange={(e) => handleSearch(e.target.value)}
              allowClear
            />
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreate13thSalary}
            loading={isCreating}
          >
            {t('payroll:refresh_13th_salary')}
          </Button>
        </div>
        <div className="tabs-wrapper">
          <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
            items={[
              {
                key: 'gross',
                label: t('payroll:gross_salary'),
              },
              {
                key: 'net',
                label: t('payroll:net_salary'),
              },
            ]}
          />
        </div>
      </SearchWrapper>

      <ThirteenthMonthSalaryList
        loading={isLoading}
        data={data}
        totalResult={totalResult}
        onTableChange={handleTableChange}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        refetch={fetchData}
        activeTab={activeTab}
        year={year}
      />
    </ContainerBgWhite>
  );
};

export default ThirteenthMonthSalary;
