import {
  AddPayrollResponse,
  AdjustmentSalaryPayload,
  ConfirmSalaryPayload,
  ContractSalary,
  Dependent,
  IAdjustmentSalary,
  IAdvanceFilters,
  IAdvanceList,
  IAllowanceList,
  IAllowanceYearByUserIdFilters,
  IBonusFilters,
  IBonusList,
  IContractSalaryFilters,
  IDependentFilters,
  ListFilterAllowance,
  ListParams,
  ListResponse,
  ListResponseAllowance,
  PayrollPayload,
  PayrollYear,
  ResetConfirmPayrollPayload,
  ResetConfirmProbationPayload,
  StatusTabPayroll,
  SuccessResponse,
  SuccessResponsePayrollYear,
  ThirteenthMonthSalaryData,
} from 'types';
import axiosClient from './axiosClient';
import queryString from 'query-string';

const payrollApi = {
  add(payload: PayrollPayload): Promise<SuccessResponse<AddPayrollResponse>> {
    return axiosClient.post('/payroll', payload);
  },

  getContractSalaryListByMonth(
    params: Partial<IContractSalaryFilters>,
  ): Promise<SuccessResponse<ListResponse<ContractSalary>>> {
    return axiosClient.get('/contract-configure/search', {
      params,
    });
  },

  getContractSalaryListByYear(
    params: Partial<IContractSalaryFilters>,
  ): Promise<SuccessResponse<ListResponse<ContractSalary>>> {
    return axiosClient.get('/contract-configure/salary-year', {
      params,
    });
  },

  exportContractSalaryByMonth(
    payload: Partial<IContractSalaryFilters>,
    userIds: string[],
  ): Promise<any> {
    return axiosClient.post(
      '/contract-configure/export',
      {
        ...payload,
        userIds,
      },
      {
        responseType: 'blob',
      },
    );
  },

  exportContractSalaryByYear(
    payload: Partial<IContractSalaryFilters>,
    userIds: string[],
  ): Promise<any> {
    return axiosClient.post(
      '/contract-configure/export-year',
      {
        ...payload,
        userIds,
      },
      {
        responseType: 'blob',
      },
    );
  },

  confirmContractSalary(payload: ConfirmSalaryPayload): Promise<SuccessResponse<any>> {
    return axiosClient.post('/confirm/payroll/salary', payload);
  },

  getAllUserHaveAllowance(
    params: Partial<ListFilterAllowance>,
  ): Promise<SuccessResponse<ListResponseAllowance<IAllowanceList>>> {
    return axiosClient.get('/allowance-details/search', {
      params: params,
    });
  },

  getAllowanceYearByUserId(
    params: Partial<IAllowanceYearByUserIdFilters>,
  ): Promise<SuccessResponse<ListResponseAllowance<IAllowanceList>>> {
    return axiosClient.get('/allowance-details/search-history', {
      params: params,
    });
  },

  getPayrollByYear(
    params: Partial<ListParams>,
  ): Promise<SuccessResponsePayrollYear<Array<PayrollYear>>> {
    return axiosClient.get('/payroll/payroll-by-year', {
      params,
    });
  },

  getThirteenthMonthSalary(
    params: Partial<ListParams>,
  ): Promise<SuccessResponsePayrollYear<Array<ThirteenthMonthSalaryData>>> {
    return axiosClient.get('/payroll/payroll-by-year', {
      params,
    });
  },

  confirmAllowance(payload: ConfirmSalaryPayload): Promise<SuccessResponse<any>> {
    return axiosClient.post('/confirm/payroll/allowance', payload);
  },

  resetConfirmPayroll(payload: ResetConfirmPayrollPayload): Promise<SuccessResponse<any>> {
    return axiosClient.post('/confirm/cancel-payroll-tab', payload);
  },
  resetConfirmProbation(payload: ResetConfirmProbationPayload): Promise<SuccessResponse<any>> {
    return axiosClient.post('/payroll/details/reset-probation', payload);
  },

  getAllUserHaveDependent(
    params: Partial<IDependentFilters>,
  ): Promise<SuccessResponse<ListResponse<Dependent>>> {
    return axiosClient.get('/dependant/search', {
      params,
    });
  },

  confirmDependent(payload: ConfirmSalaryPayload): Promise<SuccessResponse<any>> {
    return axiosClient.post('/confirm/payroll/dependant', payload);
  },

  getAllUserHaveBonus(
    params: Partial<IBonusFilters>,
  ): Promise<SuccessResponse<ListResponse<IBonusList>>> {
    return axiosClient.get('/bonus-details/search', {
      params,
    });
  },

  getAdjustmentSalaryList(
    params: Partial<AdjustmentSalaryPayload>,
  ): Promise<SuccessResponse<ListResponse<IAdjustmentSalary>>> {
    return axiosClient.get('/user-adjust-salary/search', {
      params,
    });
  },

  confirmBonus(payload: ConfirmSalaryPayload): Promise<SuccessResponse<any>> {
    return axiosClient.post('/confirm/payroll/bonus', payload);
  },

  getStatusTabPayroll(monthYear: string): Promise<SuccessResponse<StatusTabPayroll>> {
    return axiosClient.get('/confirm/check-confirm', {
      params: { monthYear },
    });
  },

  getAllUserHaveAdvance(
    params: Partial<IAdvanceFilters>,
  ): Promise<SuccessResponse<ListResponse<IAdvanceList>>> {
    return axiosClient.get('/user-advance/search', {
      params,
    });
  },

  confirmAdvance(payload: ConfirmSalaryPayload): Promise<SuccessResponse<any>> {
    return axiosClient.post('/confirm/payroll/advance', payload);
  },

  checkConfirmAdjustmentSalary(params: {
    monthYear: string;
    type: string;
  }): Promise<SuccessResponse<{ isConfirm: boolean }>> {
    return axiosClient.get('/confirm/check-confirm-type', {
      params,
    });
  },
  checkConfirmProbationSalaray(params: {
    monthYear: string;
    type: string;
  }): Promise<SuccessResponse<{ isConfirmProbationSalaray: boolean }>> {
    return axiosClient.get('/confirm/check-confirm-type', {
      params,
    });
  },

  confirmAdjustmentSalary(payload: {
    monthYear: string;
    status: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post('/confirm/payroll/adjust', payload);
  },

  confirmProbationSalaray(payload: {
    monthYear: string;
    prlIdDetails: any;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post('/payroll/details/confirm-probation', payload);
  },

  exportAdjustmentSalary(payload: { monthYear: string; userIds: string[] }): Promise<any> {
    return axiosClient.post('/user-adjust-salary/export', payload, {
      responseType: 'blob',
    });
  },

  createPayrollDetails(payload: { monthYear: string; workFlowTypeId: number }): Promise<any> {
    console.log(payload);
    return axiosClient.post('/confirm/create-payroll', payload);
  },

  getDetailPayslipMonth(monthYear: string): Promise<any> {
    return axiosClient.get(`payroll/view-payslip?${queryString.stringify({ monthYear })}`);
  },

  alertUseWorkflow: (params: { workFlowTypeId: string }) => {
    const response = axiosClient.get(
      `/payroll/alert-use-workflow?${queryString.stringify(params)}`,
    );
    return response;
  },

  exportDependent(payload: { monthYear: string; employeeIds: string[] }): Promise<any> {
    return axiosClient.post('/dependant/export-dependant-user', payload, {
      responseType: 'blob',
    });
  },

  sendOTPViewPayroll(payload: { email: string; type: 'insurance' | 'payroll' }): Promise<any> {
    return axiosClient.post('/user-otp/send-otp-view-money', payload);
  },

  verifyOTPViewPayroll(payload: { id: string; otp: string; userId: string }): Promise<any> {
    return axiosClient.post('/user-otp/verify-otp-view-money', payload);
  },

  deletePayroll13th: (userId: string, year: string) =>
    axiosClient.delete(`/payroll/delete-payroll-13th/${userId}/${year}`),

  getPayroll13thList: (params: any) => {
    return axiosClient.get('/payroll/list-payroll-13th', { params });
  },

  createPayroll13th: (year: string) => axiosClient.post('/payroll/payroll-13th', { year }),
};

export default payrollApi;
